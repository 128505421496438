<template>
    <v-app id="bg_one">
         <div id="layout_top_one">
            
        </div>

        <div class="one_content d-flex flex-column justify-space-between align-center pa-10">
        <v-row align="center" justify="space-around">
            <v-col cols="12" class="d-flex flex-column align-center pt-10">
                <h3 style="color:white;" class="text-center mt-10 t_soal">{{ questions.text }}</h3>
                <p style="color:white;" class="text-center mt-2 t_result mb-10">* Tidak berpengaruh ke result*</p>

                <b-button v-for="(response, index) in questions.responses" 
                    @click="saveAnswer(index)"
                    :key="index"
                    pill
                    block
                    size="lg"
                    class="mt-5 shadow bg-white"
                    style="background-color:white;height:45px; font-size:10px;border-color:white;color:#F37679;">
                    <span class="text_btn">
                        {{ response.text}}
                    </span>
                </b-button>
            </v-col>
        </v-row>
        </div>
    </v-app>
</template>

<style>
    @font-face {
        font-family: Poppins;
        src: url('../assets/fonts/Poppins-Medium.ttf');
    }
    #bg_one {
        width: 100%;
        height: 100%;
        background: #F37679;
    }
    .one_content{
        z-index:6;
        margin-top:90px;
    }
    .t_soal{
        font-weight: 700;
        font-style: normal;
        font-size: 18px;
    }
    .t_result{
        font-weight: 400;
        font-style: italic;
        font-size:9px;
    }
    .text_btn{
        font-weight: 600;
        font-style: normal;
        font-size:12px;
    }
    #layout_top_one {
        position: fixed;
        width: 100%;
        height: 100%;
        background: url('../assets/images/bg.png') no-repeat;
        background-size: 100%;  
    }
    @media (min-width : 1000px) {
        #layout_top_one{
            width : 100%;
            height : 100%;
            position :fixed;
        }
    }

</style>

<script>
export default {
  name: 'QuestionOne',
  data: () => ({
     questions: {
        text: "Jujur deh, kamu perokok atau bukan ?",
        responses: [
            { text: "Enggak dong!" },
            { text: "Yaa sekali-sekali kalau diajak temen" },
            { text: "Gue anaknya sehat abis!" }
        ]
     }
  }),
  methods: {
    saveAnswer (index) {
        localStorage.setItem('1', index)
        this.$router.push({ name: 'QuestionTwo'})
    },
    changeColor(){
        var metaThemeColor = document.querySelector("meta[name=theme-color]");
            metaThemeColor.setAttribute("content", "#F37679");
    }
  },
  created() {
      this.changeColor();
  },
}
</script>
