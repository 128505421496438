import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/question-two',
    name: 'QuestionTwo',
    component: () => import('../components/question/QuestionTwo.vue')
  },
  {
    path: '/question-three',
    name: 'QuestionThree',
    component: () => import('../components/question/QuestionThree.vue')
  },
  {
    path: '/question-four',
    name: 'QuestionFour',
    component: () => import('../components/question/QuestionFour.vue')
  },
  {
    path: '/question-five',
    name: 'QuestionFive',
    component: () => import('../components/question/QuestionFive.vue')
  },
  {
    path: '/question-six',
    name: 'QuestionSix',
    component: () => import('../components/question/QuestionSix.vue')
  },
  {
    path: '/question-seven',
    name: 'QuestionSeven',
    component: () => import('../components/question/QuestionSeven.vue')
  },

  {
    path: '/submit-question',
    name: 'SubmitQuestion',
    component: () => import('../components/question/SubmitQuestion.vue')
  },

  {
    path: '/result-a',
    name: 'result-a',
    component: () => import('../components/question/ResultQuestionA.vue')
  },
  {
    path: '/result-b',
    name: 'result-b',
    component: () => import('../components/question/ResultQuestionB.vue')
  },
  {
    path: '/result-c',
    name: 'result-c',
    component: () => import('../components/question/ResultQuestionC.vue')
  },
  {
    path: '/product-box',
    name: 'product-box',
    component: () => import('../components/question/ProductBox.vue')
  },
  {
    path: '/product-unbox',
    name: 'product-unbox',
    component: () => import('../components/question/ProductUnbox.vue')
  },

  {
    path: '/product-overviews-01',
    name: 'product-overviews-01',
    component: () => import('../components/question/ProductOverview1.vue')
  },
  {
    path: '/product-overviews-02',
    name: 'product-overviews-02',
    component: () => import('../components/question/ProductOverview2.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
